import Api from "@/Services/Api";

export default {
    async getBasesList() {
        let response = await Api().get("/support-vision/bases-client")
        return response
    },
    async getBaseById(id) {
      let response = await Api().get(`/support-vision/bases-client/${id}`)
        return response.data
    },
    async createBase(data) {
        let response = await Api().post(`/support-vision/bases-client/`, {base: data})
        return response.data
    },
    async createLogoBase(data) {
        await Api().post(`/support-vision/bases-client/logo/`, data)
        return data
    },
    updateLogoBase(id, data) {
        let response = Api().put(`/support-vision/bases-client/logo/${id}`, data)
        return response
    }, 
    async updateBase(id, data) {
        let response = await Api().put(`/support-vision/bases-client/${id}`, {base: data})
        return response
    },
    async removeBase(id) {
        let response = await Api().delete(`/support-vision/bases-client/${id}`)
        return response
    },
    async createParams(data) {
        let response = await Api().post(`/support-vision/bases-client/params`,{params: data})
        return response
    },
    async editParams(id, data) {
        let response = await Api().put(`/support-vision/bases-client/params/${id}`, {params: data})
        return response
    },
    async getParams() {
        let response = await Api().get(`/support-vision/bases-client/params`)
        return response.data
    },
    async getParamsByType(type) {
        let response = await Api().get(`/support-vision/bases-client/params/${type}`)
        return response.data
    },
    async removeParams(id) {
        let response = await Api().delete(`/support-vision/bases-client/params/${id}`)
        return response
    },
    async getBasesByStatut(statut) {
        let response = await Api().get(`/support-vision/bases-client/statut/${statut}`)
        return response
    }
}